@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,600,700)

$break: 768px

body
  margin: 1em
  font-family: 'Open Sans', sans-serif
  font-weight: 300
  color: #031634
  background-color: #f0f0f0
  @media (min-width: $break)
    font-size: 2em

main
  background-color: #e8ddcb
  max-width: 300px
  margin: 0 auto
  padding: 1em
  position: relative
  box-shadow: 0 1px 5px rgba(0, 0, 0, .3), 0 0 50px rgba(0, 0, 0, .1) inset

  @media (min-width: $break)
    max-width: 500px

main::before
  content: ""
  position: absolute
  z-index: -1
  box-shadow: 0 0 20px rgba(0, 0, 0, .1)
  top: 0
  bottom: 0
  left: 0
  right: 0
  border-radius: 25%

h1
  margin: 0
  padding: 0
  font-weight: 300
  font-size: 1.75em
  text-align: center
  letter-spacing: -2px

  @media (min-width: $break)
    letter-spacing: -3px

h1 span
  font-weight: 700

#date
  font-size: 0.75em
  text-align: center
  margin-bottom: 1em

table
  width: 100%

tr.total
    font-weight: 600

td + td
  text-align: right

hr
  border: 0
  margin: 0.5em 0
  height: 1px
  background: linear-gradient(to right, rgba(3, 22, 52, .25), rgba(3, 22, 52, 1), rgba(3, 22, 52, .25))
